(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Registration
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('Registration', Registration);

  function Registration($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/auth/register');
  }
}());
